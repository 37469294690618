import "./App.css";
import EmailSignature from "./components/EmailSignature";

const contacts = [
  {
    name: "Ryan Kew",
    title: "CX Consultant",
    logo: "bolder-cx-small-black.png",
    linkedIn: "https://www.linkedin.com/in/ryan-kew-4b9947120/",
  },
  {
    name: "Marcus Revington",
    title: "Managing Director",
    logo: "bolder-soft-small-black.png",
    linkedIn: "https://www.linkedin.com/in/marcus-revington-b93a3623/",
  },
  {
    name: "Luke Fothergill",
    title: "Principal Consultant",
    logo: "bolder-black.png",
    linkedIn: "https://www.linkedin.com/in/luke-fothergill-3141133a/",
  },
  {
    name: "Volodymyr Shtyka",
    title: "Developer",
    logo: "bolder-soft-small-black.png",
    linkedIn: "https://www.linkedin.com/in/vshtyka/",
  },
  {
    name: "James Quinton",
    title: "Developer",
    logo: "bolder-soft-small-black.png",
    linkedIn: "https://www.linkedin.com/in/james-quinton-5b531713a/",
  },
];

function App() {
  return (
    <div className="App">
      {contacts.map((contact, index) => (
        <EmailSignature contact={contact} key={index} />
      ))}
    </div>
  );
}

export default App;

const EmailSignature = ({ contact }) => {
	return (
		<section>
			<table
				width="100%"
				cellSpacing="0"
				cellPadding="0"
				style={{
					fontFamily: "Helvetica, Arial, sans-serif",
					position: "relative",
					maxWidth: "600px",
				}}
			>
				<tbody>
					<tr>
						<td>
							<table
								cellPadding="0"
								cellSpacing="0"
								width="100%"
								style={{ border: "none" }}
							>
								<tbody>
									<tr style={{ border: "none" }}>
										<td style={{ border: "none" }}>
											<table
												cellPadding="0"
												cellSpacing="0"
												style={{
													backgroundImage: "initial",
													backgroundPosition: "initial",
													backgroundRepeat: "initial",
													border: "none",
												}}
											>
												<tbody>
													<tr height="125" style={{ border: "none" }}>
														<td
															width="251"
															height="125"
															valign="middle"
															style={{ border: "none" }}
														>
															<table
																cellPadding="0"
																cellSpacing="0"
																style={{ border: "none", width: "100%" }}
															>
																<tbody>
																	<tr style={{ border: "none" }}>
																		<td style={{ border: "none" }}>
																			<h1
																				style={{
																					fontSize: "20px",
																					color: "#0f0e0f",
																					fontFamily:
																						"Helvetica, Arial, sans-serif",
																					marginTop: "unset",
																					marginRight: "unset",
																					marginLeft: "unset",
																					marginBottom: "4px",
																				}}
																			>
																				{contact.name}
																			</h1>
																		</td>
																	</tr>
																	<tr style={{ border: "none" }}>
																		<td style={{ border: "none" }}>
																			<h2
																				style={{
																					fontSize: "16px",
																					color: "#0f0e0f",
																					fontFamily:
																						"Helvetica, Arial, sans-serif",
																					marginTop: "unset",
																					marginRight: "unset",
																					marginLeft: "unset",
																					marginBottom: "10px",
																					fontWeight: "normal",
																				}}
																			>
																				{contact.title}
																			</h2>
																		</td>
																	</tr>
																	<tr
																		style={{ border: "none" }}
																		height="15"
																	></tr>
																	<tr style={{ border: "none" }}>
																		<td
																			valign="bottom"
																			style={{ border: "none" }}
																		>
																			<img
																				alt="Bolder Logo"
																				src={`/${contact.logo}`}
																				width="200"
																				style={{ border: "none" }}
																			/>
																		</td>
																	</tr>
																	<tr
																		style={{ border: "none" }}
																		height="5"
																	></tr>
																	<tr style={{ border: "none" }}>
																		<td style={{ border: "none" }}>
																			<table
																				cellPadding="0"
																				cellSpacing="0"
																				style={{ border: "none" }}
																			>
																				<tbody>
																					<tr style={{ border: "none" }}>
																						<td style={{ border: "none" }}>
																							<a
																								href="tel:01278226365"
																								style={{
																									fontSize: "14px",
																									textDecoration: "unset",
																									color: "#0f0e0f",
																									whiteSpace: "nowrap",
																								}}
																								rel="noreferrer"
																								target="_blank"
																							>
																								01278 226365
																							</a>
																							<span
																								style={{
																									padding: "0 10px 0 5px",
																									color: "#0f0e0f",
																								}}
																							></span>
																						</td>
																						<td style={{ border: "none" }}>
																							<a
																								href={`https://www.bolderdigital.com/?utm_medium=email&amp;utm_source=Email+signature&amp;utm_content=${contact.name.replace(
																									/ /g,
																									"+"
																								)}`}
																								style={{
																									fontSize: "14px",
																									textDecoration: "unset",
																									color: "#0f0e0f",
																								}}
																								rel="noreferrer"
																								target="_blank"
																							>
																								bolderdigital.com
																							</a>
																							<span
																								style={{
																									padding: "0 10px 0 5px",
																									color: "#0f0e0f",
																								}}
																							></span>
																						</td>
																						{contact.linkedIn && (
																							<td style={{ border: "none" }}>
																								<a
																									href={contact.linkedIn}
																									style={{
																										textDecoration: "unset",
																									}}
																									rel="noreferrer"
																									target="_blank"
																								>
																									<img
																										src="/linkedin.png"
																										width="20"
																										alt=""
																									/>
																								</a>
																							</td>
																						)}
																					</tr>
																				</tbody>
																			</table>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<table
				cellPadding="0"
				cellSpacing="0"
				style={{ border: "none", padding: "10px 0", maxWidth: "600px" }}
			>
				<tbody>
					<tr style={{ border: "none" }}>
						<td style={{ border: "none" }}>
							<p
								style={{
									fontSize: "10px",
									lineHeight: "13px",
									fontFamily: "Helvetica, Arial, sans-serif",
									color: "#7a7a7a",
									margin: "0",
								}}
							>
								The content of this email is confidential and intended for the
								recipient specified in message only. It is strictly forbidden to
								share any part of this message with any third party, without a
								written consent of the sender. If you received this message by
								mistake, please reply to this message and follow with its
								deletion, so that we can ensure such a mistake does not occur in
								the future.
							</p>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
	);
};

export default EmailSignature;
